<template>
  <b-overlay :show="fetching" rounded="sm" >
      <div class="page">
        <div class="page__title">{{ getModuleConfig('staff_list_page.form_title') }}</div>
        <div class="page__desc text-center">{{ getModuleConfig('staff_list_page.form_desc') }}</div>

        <div class="field" v-if="isCanBinding">
          <div v-if="branchLevel.length == 0" class="mb-2">
            <SharedSelect
              class="mt-2"
              :value="null"
              placeholder="查無資料"
            />
          </div>
          <!-- branch select -->
          <div v-for="(level, index) in branchLevel" :key="level" class="mb-2">
            <SharedSelect
                class="mt-2"
                v-model="temporaryBranch[index]"
                :options="providerOptions[level].options"
                :placeholder="displayPlaceholder"
            />
          </div>
          <!-- branch select -->
          <!-- <template v-if="fetchingStaff || true">
            <div class="text-center mt-3 mb-3 loading-spinner">
                <b-spinner variant="secondary" small />
            </div>
          </template> -->


          <div class="sales__wrapper">
            <swiper v-if="staffs.length > 0" :options="swiperOption" class="sales align-items-stretch" >
              <swiper-slide  v-for="(i, idx) in staffs" :key="idx" class="sales__item">
                <div class="card text-center d-flex flex-column">

                  <div v-if="showAvatar">
                    <img
                      v-if="displayAvatar(i)"
                      :src="displayAvatar(i)"
                      class="card-img-top"
                    />
                    <img
                      v-else
                      src="./default_staff_img.jpg"
                      class="card-img-top"
                    />
                  </div>

                  <div class="p-3 flex-fill d-flex flex-column">
                    <div class="flex-fill">
                    <table v-if="staffFields.length != 0" class="mx-auto my-3 align-text-top" >
                      <tr v-for="(field, index) in staffFields" :key="index">
                        <template v-if="field.title">

                              <td class="font-weight-bold text-nowrap align-text-top">{{ field.title }}</td>
                              <td class="text-left align-text-top">{{ displayField(i, field) }}</td>

                        </template>
                        <template v-else>
                          <td colspan="2" class="align-text-top">{{ displayField(i, field) }}</td>
                        </template>
                      </tr>
                    </table>
                    <template v-else>
                      <div>{{ i.name }}</div>
                    </template>
                  </div>


                    <SharedButton
                      class="s-btn-bg-primary mt-2 sales__button"
                      :class="selectedStaff.id == i.id ? 'sales__button__active' : ''"
                      @click="selectStaff(i)"
                    >
                      <i class="fa fa-check mr-2"></i>{{ getModuleConfig('staff_list_page.staffs_list.select_button_text') }}
                    </SharedButton>
                </div>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev shadow-lg" slot="button-prev"></div>
              <div class="swiper-button-next shadow-lg" slot="button-next"></div>
            </swiper>
          </div>
        </div>
        <div v-else>
          <div
            class="page__content"
            v-if="getModuleConfig('staff_list_page.multiple_bind.forbid_text')"
            v-html="getModuleConfig('staff_list_page.multiple_bind.forbid_text')"
          ></div>
        </div>
      </div>

      <div class="page__button s-space-y-4">
        <MemberButton class="s-btn-bg-primary mb-2"
          v-if="isCanBinding"
          :disable-button="!selectedStaff.id"
          @custom-click="handleSubmit"
          :button-text="displayButtonText"
          :button-url="getModuleConfig('staff_list_page.bind_button.button_url')"
          :type="getModuleConfig('staff_list_page.bind_button.button_action')"
          :use-callback="true"
        >
        </MemberButton>
        <MemberButton
          :button-style="`s-btn-outline-primary`"
          v-if="getModuleConfig('staff_list_page.button_text') && isCanBinding"
          :button-text="getModuleConfig('staff_list_page.button_text')"
          :button-url="getModuleConfig('staff_list_page.button_url')"
          :type="getModuleConfig('staff_list_page.button_action')"
        >
        </MemberButton>
        <!-- 不能多重綁定的按鈕 -->
        <MemberButton
          :button-style="`s-btn-outline-primary`"
          v-if="getModuleConfig('staff_list_page.multiple_bind.button_text') && !isCanBinding"
          :button-text="getModuleConfig('staff_list_page.multiple_bind.button_text')"
          :button-url="getModuleConfig('staff_list_page.multiple_bind.button_url')"
          :type="getModuleConfig('staff_list_page.multiple_bind.button_action')"
        >
        </MemberButton>
      </div>

  </b-overlay>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
//import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapGetters } from "vuex";
import liff from "@line/liff";
import staffApi from "@/apis/liff/v2/staff";
import _ from "lodash";
import branchMixin from "@/mixins/Dashboard/branches";
import mixinStaff from "@/mixins/liff/staff";
import LiffChecker from "@/utils/LiffChecker";

export default {
  mixins: [branchMixin, mixinStaff],
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    branchProvider() {
      return this.getModuleConfig("staff_list_page.branch_provider");
    },
    showAvatar() {
      return this.getModuleConfig("staff_list_page.staffs_list.show_avatar") ?? true;
    },
    claimsForWatcher() {
      return _.cloneDeep(this.temporaryBranch);
    },
    tags() {
      return this.temporaryBranch.map((branch, index) => {
        return (this.providerOptions[this.branchLevel[index]].options).find((p) => p.value == branch)?.text
      });
    },
    staffFields() {
      return (
        this.getModuleConfig("staff_specific_page.staffs_list.fields") ?? []
      ).sort((a, b) => a.order - b.order);
    },
    displayPlaceholder() {
      const text = this.getModuleConfig('staff_list_page.default_select_text')
      return (text == '' || text == null || text == 'null') ? '請選擇區域櫃點' : text
    },
    displayButtonText() {
      if (this.selectedStaff.id) {
        const text = this.getModuleConfig('staff_list_page.bind_button.button_text')
        return this.templateReplace(text, { staff: this.selectedStaff })
      } else {
        return this.getModuleConfig('staff_list_page.bind_button.button_default_text')
      }
    },
    displaySendLineMessage() {
      const text = this.getModuleConfig("staff_list_page.bind_button.send_messages.text")
      return this.templateReplace(text, { staff: this.selectedStaff })
    },
    showBranchCode() {
      return this.getModuleConfig("staff_list_page.show_branch_code") ?? true
    },
    swiperOption() {
      // todo single one how to show
      return {
        //loop: true,
        slidesPerView: "auto",
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        wrapperClass:'swiper-wrapper',
        //width: 220, // 不知為啥 他是 px*2數字 然後會扣掉spaceBetween(px) 才會是他Card Width
        spaceBetween: 10,
        breakpoints: {
          768: {
            //width: undefined,
            spaceBetween: 30,
          },
        },
      }
    }
  },
  components: {
    MemberButton,
    SharedSelect,
    SharedButton,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      fetching: true,
      selectedStaff:{
        id: null,
      },
      staffs: [],
      providerOptions: {},
      branchId: null,
      temporaryBranch: [null],
      branchLevel: [],
      isLastBranch: false,
      lineData: {},
      fetchingStaff: false,
      isCanBinding: true
    };
  },
  watch: {
    "claimsForWatcher": {
      handler: function (newVal, oldVal) {
        if (newVal.slice(-1)[0] == null) {
          this.staffs = []
        }
        this.selectedStaff = {
          id: null,
        }
        if (newVal.slice(-1)[0] === oldVal.slice(-1)[0]) {
          for (var i = newVal.length - 1; i >= 0; i--) {
            if (newVal[i] !== oldVal[i]) {
              this.temporaryBranch = this.temporaryBranch.slice(0, i+1)
              this.branchLevel = this.branchLevel.slice(0, i+1)
            }
          }
        } else {
          if (newVal.length < oldVal.length) {
            this.branchLevel = this.branchLevel.slice(0, newVal.length+1)
          }
          const checkNullIndex = newVal.indexOf(null)
          if (checkNullIndex !== this.branchLevel.length - 1) {
            if (checkNullIndex !== -1) {
              this.branchLevel = this.branchLevel.slice(0, checkNullIndex + 1)
              this.isLastBranch = false
              if (checkNullIndex !== newVal.length - 1) {
                this.temporaryBranch = this.temporaryBranch.slice(0, checkNullIndex + 1)
              }
            } else {
              this.branchLevel = this.branchLevel.slice(0, newVal.length)
              this.branchId = newVal.slice(-1)[0]
              this.fetchBranches(false)
            }
          } else {
            this.isLastBranch = false
          }
        }
      },
      deep: true
    },
  },

  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.checkBinding();
      if (!this.isCanBinding) {
        this.fetching = false;
        return
      }
      await liff
        .init({
          liffId: this.liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.href,
            });
          }
        })
        .catch(() => {
          alert("初始化頁面失敗，請確認此網址是否正確");
        });
      const checker = new LiffChecker(this.$route.params.orgCode);
      this.lineData = await checker.getLineData();
      await this.fetchBranches(true);
      this.fetching = false;
    },
    async fetchBranches(init = false) {
      if (!this.branchProvider) {
        alert("請設定分店提供者");
        return;
      }

      //this.staffs = [];
      if (init) {
        this.fetchBranchesForInit();
      } else {
        const providerSetting = this.branchProvider.split(':');
        if ((providerSetting.length === 2)
          || providerSetting[2] === 'true'
          || this.branchProvider === 'waltily.branch'
        ) {
          this.providerOptions[this.branchLevel.slice(-1)[0] + 1] = {}
          const response = await staffApi.getBranches({ type: 'level', branch_id: this.branchId });
          const source = response.data.data
          if (source) {
            const level = source[0].level
            this.providerOptions[level] = {}
            this.providerOptions[level].options = this.sortBranch(source, source[0]['org_id'], this.showBranchCode)
            if (level < this.branchLevel.slice(-1)[0]) {
              this.branchLevel[this.branchLevel.length - 1] = level
            } else if (this.branchLevel.indexOf(level) === -1) {
              this.branchLevel.push(level)
            }
            this.temporaryBranch.push(null)
            this.isLastBranch = false
          } else {
            if (this.branchLevel.length > this.temporaryBranch.filter(Boolean).length) {
              this.branchLevel = this.branchLevel.slice(0, this.temporaryBranch.filter(Boolean).length)
            }
            this.isLastBranch = true
          }
        } else {
          this.isLastBranch = true
        }
      }

      if (this.isLastBranch) {
        this.fetchStaffs()
      } else {
        this.staffs = []
      }

      this.$forceUpdate();
    },
    async fetchBranchesForInit() {
      this.providerOptions = []
      let response = null
      const providerSetting = this.branchProvider.split(':');

      let params = {}
      switch (true) {
        case providerSetting[1] === 'all':
          // 全部分店
          params = { type: 'all' }
          break;
        case providerSetting[1] === 'level':
        case providerSetting[1] === undefined:
          // 階級獲取 只是從parent_id - null
          params = { type: 'level', branch_id: null }
          break;
        default:
          // 階級獲取 從特定分店開始
          params = { type: 'specific', branch_code: providerSetting[1] }
          break;
      }

      try {
        response = await staffApi.getBranches(params);

        const source = response.data.data
        const level = source[0].level
        this.providerOptions[level] = {}
        this.providerOptions[level].options = this.sortBranch(source, source[0]['org_id'], this.showBranchCode)
        this.branchLevel.push(level)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchStaffs() {
      this.fetching = true;
      await staffApi.getStaffs(this.branchId, { filter_by_module:true }).then((res) => {
        this.staffs = res.staffs?.sort((a, b) => a.order - b.order);
        this.fetching = false;
      });
    },
    async handleSubmit(callback) {
      try {
        this.fetching = true;
        await this.doSubmit();
        callback(null);
      } catch (error) {
        this.fetching = false;
        callback(error);
      }
    },
    doSubmit() {
      return new Promise((resolve, reject) => {
        if (this.getModuleConfig('staff_list_page.bind_button.send_messages.enable')) {
          this.sendLineMessage()
        }
        staffApi.customerBindStaff({
          line_id: this.lineData.profile.userId,
          line_access_token: this.lineData.accessToken,
          sid: this.selectedStaff.id,
          tags: this.tags,
          from_page: 'staff_list_page',
        }).then(() => {
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      });
    },
    sendLineMessage() {
      liff
        .sendMessages([
          {
            type: "text",
            text: this.displaySendLineMessage,
          },
        ])
        .then(() => {
          console.log("message sent");
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    displayAvatar(staff) {
      return staff.avatar_url ?? this.getModuleConfig("staff_list_page.staffs_list.default_avatar_url")
    },
    displayField(staff, field) {
      if (field.type == 'date') {
        return this.$moment(staff[field.matched_key]).format('YYYY-MM-DD')
      }
      return staff[field.matched_key] ?? ''
    },
    selectStaff(staff) {
      this.selectedStaff = staff;
    },
    async checkBinding() {
      await staffApi.checkBinding().then((res) => {
        // 如果綁定 且可以多重綁定
        if (res.isBound) {
          if (this.getModuleConfig("staff_list_page.multiple_bind.enable")) {
            this.isCanBinding = true
          } else {
            this.isCanBinding = false
          }
        } else {
          this.isCanBinding = true
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.sales{
  margin:5px -16px ;
  padding:16px 0;

  &__wrapper{
    //min-height: 460px;
  }


  &__button{
    opacity: .5;
    &__active{
      opacity: 1;
    }
  }

  &__item{
    width: 220px;
    height: revert;

    .card{
      border-radius: .5rem;
      height: 100%;

      .card-img-top{
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
      }
    }
  }

  ::v-deep.swiper-wrapper{
    align-items: stretch;
  }

  .swiper-button-next,
  .swiper-button-prev{
    color: gray;
    background: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: auto;
    &::after{
      font-size: 1.5rem;
    }
  }

}

.page{
  padding:5px 5px ;
}


::v-deep .spinner-border{
  border-width: 0.2em !important;
}


</style>
